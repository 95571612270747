import * as React from "react"
import tw, { styled } from "twin.macro"
import LogoImg from "../images/logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhoneOffice } from "@fortawesome/pro-light-svg-icons"

const Header = ({ title }) => {
  const HeaderRoot = tw.header`absolute flex items-center justify-between px-8 top-8 w-full`
  const Logo = tw.img`h-[42px] w-auto sm:(h-[48px])`
  const Contact = styled.ul({
    ...tw`flex gap-4 sm:(flex-col gap-0)`,
    svg: tw`text-xl sm:(mr-4 text-base)`,
    span: tw`hidden sm:(inline-flex)`,
  })
  return (
    <HeaderRoot>
      <Logo src={LogoImg} alt={title} title={title} />
      <Contact>
        <li className="contact__t">
          <a href="tel:+447781427886">
            <FontAwesomeIcon icon={faPhoneOffice} fixedWidth />
            <span>07781 427886</span>
          </a>
        </li>
        <li className="contact__e">
          <a href="mailto:info@messengerwest.gg">
            <FontAwesomeIcon icon={faEnvelope} fixedWidth />
            <span>info@messengerwest.gg</span>
          </a>
        </li>
      </Contact>
    </HeaderRoot>
  )
}

export default Header
