import * as React from "react"
import { Helmet } from "react-helmet"
import tw from "twin.macro"
import Layout from "../components/layout"
import Div100vh from "react-div-100vh"
import EmblemImg from "../images/emblem.svg"

const IndexPage = () => {
  const Content = tw(Div100vh)`flex flex-col items-center justify-center`
  const Emblem = tw.img`max-w-[360px] w-4/6`
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Messenger West | Property Development</title>
        <link rel="canonical" href="https://www.messengerwest.gg" />
        <meta
          name="description"
          content="Messenger West are a property development company based in Guernsey, founded by James Messenger and Sally West. Registered no: 40319."
        />
      </Helmet>
      <Content>
        <Emblem src={EmblemImg} alt="MW" title="MW" />
      </Content>
    </Layout>
  )
}

export default IndexPage
