import * as React from "react"
import tw from "twin.macro"

const Footer = ({ title }) => {
  const FooterRoot = tw.footer`absolute bottom-8 px-8 flex flex-col items-center text-center text-sm w-full sm:(flex-row justify-between text-base)`
  return (
    <FooterRoot>
      <span>
        <strong>Registered no:</strong> 40319
      </span>
      <span>
        ©{new Date().getFullYear()} {title} Ltd. All rights reserved.
      </span>
    </FooterRoot>
  )
}

export default Footer
